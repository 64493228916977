//---------------------------------------------
//
//  Variables
//
//    1. Bootstrap Fonts
//    2. Bootstrap Globals
//    3. Bootstrap Colors
//    4. Bootstrap Container Width
//
//---------------------------------------------

//---------------------------------------------
// 1. Bootstrap Fonts
//---------------------------------------------

// $font-family-base: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
$font-family-base: "Lexend", sans-serif;

//---------------------------------------------
// 2. Bootstrap Globals
//---------------------------------------------

$enable-rounded: true;
$enable-shadows: false;
$enable-gradients: false;

//---------------------------------------------
// 3. Bootstrap Colors
//---------------------------------------------

$white: #fff;

$body-color: #1c1f35; // The default text color

$primary: #0d6efd;
$secondary: #6c757d;
$success: #198754;
$info: #0dcaf0;
$warning: #ffc107;
$danger: #dc3545;
$light: #f8f9fa;
$dark: #212529;
// custom colors
$black: #1C1F35;
$red: #EE2626;
$grey: #666C89;

// The contrast ratio to reach against white, to determine if color changes from "light" to "dark". Acceptable values for WCAG 2.0 are 3, 4.5 and 7.
// See https://www.w3.org/TR/WCAG20/#visual-audio-contrast-contrast
$min-contrast-ratio: 2.5;

//
// Body
//
// Settings for the `<body>` element.

$body-color: $black; // The default text color
// $body-bg: #141617;
// $body-emphasis-color: $white;

//
// Links
//
// Style anchor elements.

$link-color: $red;

//
// Typography
//
// Font, line-height, and color for body text, headings, and more.

$font-size-root: 14px;
// $headings-color: inherit;

// Navs

$nav-link-padding-y: 2rem;

// Navbar
$navbar-nav-link-padding-x: 2rem;
$navbar-light-active-color: $red;

$dropdown-item-padding-x: 1rem;
$dropdown-item-padding-y: 1rem;

$dropdown-link-active-bg: $red;

// $border-radius: 0;
// $border-radius-sm: 0;
// $border-radius-lg: 0;
// $border-radius-xl: 0;
// $border-radius-xxl: 0;
// $border-radius-pill: 0;

// $btn-border-radius: 100px;
// $btn-border-radius-sm: 100px;
// $btn-border-radius-lg: 100px;

// $input-border-radius: 0;
// $input-border-radius-sm: 0;
// $input-border-radius-lg: 0;

// $input-border-width: 0;
// $input-bg: transparent;

// $input-box-shadow: none;
// $input-focus-box-shadow: none;

$input-placeholder-color: #B6B6B6;

// Breadcrumbs

$breadcrumb-divider-color: $white;
$breadcrumb-active-color: $white;
$breadcrumb-item-padding-x: 1rem;

//---------------------------------------------
// 4. Bootstrap Container Width
//---------------------------------------------

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  // xxxl: 1600px
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1320px,
  // xxxl: 1560px
);