/* MEDIA BREAKPOINTS
-------------------------------------------------- */

// ### DOWN
//
// From large to small devices

// No media query necessary for xs breakpoint as it's effectively `@media (max-width: 0) { ... }`
@include media-breakpoint-down(sm) {}

@include media-breakpoint-down(md) {
  #testimoni {
    background: none !important;
  }
  #carabelanja {
    .category-filter {
      .flex-md-fill {
        width: 46%;
      }
    }
  }
  #icon-bar-fixed {
    display: none;
  }
}

@include media-breakpoint-down(lg) {
  #banners {
    .item {
      .bg-img {
        background-position: center;
      }
    }
  }
}

@include media-breakpoint-down(xl) {}

@include media-breakpoint-down(xxl) {}

// ### UP
//
// From small to large devices

// No media query necessary for xs breakpoint as it's effectively `@media (min-width: 0) { ... }`
@include media-breakpoint-up(sm) {}

@include media-breakpoint-up(md) {
  #testimoni {
    header {
      color: white;
    }
  }

  #contact {
    .wrap-maps {
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 49.5vw;
    }
  }
}

@include media-breakpoint-up(lg) {
  .bg-img-head {
    padding-top: 180px;
  }

  .nav-item {
    &:not(:last-child) {
      .nav-link {

        &:before {
          position: absolute;
          content: '';
          right: 0;
          top: 50%;
          transform: translateY(-50%);
          width: 1px;
          height: calc(100% - 4rem);
          background-color: rgba(40, 40, 54, 0.2);
        }

        &.active {
          span {

            &:after {
              width: calc(100% - 30px);
            }
          }
        }
      }
    }
  }

  .dropend .dropdown-toggle::after {
    position: absolute;
    right: 1rem;
    line-height: 1.4rem;
    content: "\f105" !important;
  }

  #toko {
    // background-color: #F4F4F4;

    .bg-img {
      // background-position: center;
      // background-size: cover;
      // background-repeat: no-repeat;
      position: absolute;
      height: auto;
      margin-bottom: 0;
      top: 0;
      left: 0;
      bottom: 0;
      width: 49.5vw;
    }
  }

  #service {
    .card {
      padding: 100px 100px 0;
    }
  }

  #testimoni {
    &.bg-img-head {
      padding-top: 70px;
    }
  }
}

@include media-breakpoint-up(xl) {}

@include media-breakpoint-up(xxl) {
  #testimoni {
    &.bg-img-head {
      padding-top: 90px;
    }
  }
}

// ### ONLY
//
// Only breakpoints

@include media-breakpoint-only(xs) {
  #product-tab {
    .nav-item {
      width: 100%;
      .nav-link {
        width: 100%;
      }
    }
  }
}

@include media-breakpoint-only(sm) {}

@include media-breakpoint-only(md) {
  .bg-img-head {
    padding-top: 6rem;
  }

  #testimoni {
    &.bg-img-head {
      padding-top: 3rem;
    }
  }
  #product-tab {
    .nav-item {
      width: 33.33%;
      .nav-link {
        width: 100%;
        height: 100%;
      }
    }
  }
}

@include media-breakpoint-only(lg) {}

@include media-breakpoint-only(xl) {}

@include media-breakpoint-only(xxl) {}