//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";

// select2
@import "select2-bs5";

// If you do not want to load the customized bootstrap version, just use the following line.
// @import "~bootstrap/scss/bootstrap";

// Font Awesome
@import "fa-variables";
@import "~@fortawesome/fontawesome-free/scss/fontawesome";
@import "~@fortawesome/fontawesome-free/scss/solid";
@import "~@fortawesome/fontawesome-free/scss/brands";


//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

.icon-wa {
  color: #16AD41;
}

.icon-phone {
  color: #CC1B2B;
}

.icon-mail {
  color: #F2AE00;
}

.icon-ig {
  color: #A87E60;
}

.icon-fb {
  color: #3F5A8C;
}

.navbar-toggler {
  border: none;
  border-radius: 0;

  &:focus {
    box-shadow: none;
  }
}

.dropend .dropdown-toggle::after,
.dropdown-toggle::after {
  border: none !important;
  font: normal normal normal 14px/1 "Font Awesome 6 Free";
  content: "\f107" !important;
  margin-left: 1rem;
  vertical-align: 0;
}

#header {
  .top-bar {
    background-color: #282836;
  }
}

.nav-item {
  .nav-link {
    position: relative;
    // padding: 30px;

    // &>i {
    //   margin-left: 36px;
    // }

    &.active {
      // color: $red;

      span {

        &:after {
          position: absolute;
          content: '';
          width: 100%;
          height: 2px;
          background-color: $red;
          bottom: 0;
          left: 50%;
          transform: translateX(-50%);
        }
      }
    }
  }
}

.slick-dots {
  z-index: 10;
}

#page-banner {
  height: 353px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  .container {
    top: 60%;
    transform: translateY(-50%);
  }

  .breadcrumb {
    a {
      color: $white;
      text-decoration: none;
    }
  }
}

#banners {
  .item {
    height: calc(100vh - 163px);
    min-height: 300px;
  }

  .banner-text {
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 2;
  }

  .bg-overflow {
    background: rgba(0, 0, 0, 0.3);
    position: absolute;
    content: '';
    left: 0;
    top: 0;
    height: 100%;
    width: 100%;
    z-index: 1;
  }
  .slick-prev {
    left: 65px;
    &:before {
      font-size: 40px;
    }
  }
  .slick-next {
    right: 65px;
    &:before {
      font-size: 40px;
    }
  }
  .slick-dots {
    li {
      button {
        height: 15px;
        width: 15px;
        border: 1px solid #D9D9D9;
        border-radius: 30px;

        &:before {
          display: none;
        }
      }

      &.slick-active {
        button {
          color: #D9D9D9;
          background-color: #D9D9D9;
        }
      }
    }
  }
}

.search-light {
  height: 42px;

  .form-control {
    &::placeholder {
      color: #B6B6B6;
    }
  }

  .btn {
    border-width: 1px 1px 1px 0;
    border-color: #dee2e6;
    color: #B6B6B6;
    background-color: $white;
  }
}

.separator {
  position: relative;
  padding-top: 28px;
  padding-bottom: 36px;
  &:after {
    content: '';
    width: 65px;
    height: 2px;
    transform: translateX(-50%);
    position: absolute;
    background-color: $red;
  }

  &.left {
    &:after {
      left: 0;
      transform: none;
    }
  }

  &.center {
    &:after {
      left: 50%;
      transform: translateX(-50%);
    }
  }
}

.text-red {
  color: $red;
}

.btn-red {
  color: $white;
  background: linear-gradient(94.06deg, #CC1B2B -1.21%, #EE2626 58.66%, #F4675A 116.84%);

  &:hover {
    color: $white;
    background: linear-gradient(94.06deg, #A51622 -1.21%, #BE1E1E 58.66%, #C2413A 116.84%);
  }
}

.btn-red-outline {
  background-color: $white;
  color: $red;
  border: 1px solid $red;
  padding: 17px 35px;
  border-radius: 10px;

  &:hover {
    color: $white;
    background: linear-gradient(94.06deg, #A51622 -1.21%, #BE1E1E 58.66%, #C2413A 116.84%);
  }
}

#cta {
  h1 {
    font-size: 42px;
  }
  p {
    font-size: 20px;
  }
  .btn {
    font-size: 16px;
  }
}

#contact-list {
  li {
    a {
      color: $black;
      text-decoration: none;

      &:hover {
        text-decoration: underline;
        color: $red;
      }
    }
  }

  // .fa-inverse {
  //   line-height: 58px;

  //   &:before {
  //     font-size: 24px;
  //   }
  // }
}

#toko {
  background-color: #F4F4F4;

  .bg-img {
    background-image: url(../images/toko.png);
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;

    position: relative;
    height: 300px;
    margin-bottom: 2rem;
    // top: 0;
    // left: 0;
    // bottom: 0;
    // width: 50vw;
  }
}

#carabelanja {
  .category-filter {
    a {
      text-decoration: none;
      color: $black;

      &.active,
      &:hover {
        color: $red;
      }
    }
  }
}

.bg-img-head {
  background-size: contain;
  background-position: top center;
  background-repeat: no-repeat;
  padding-top: 3rem;
}

.img-cover {
  object-fit: cover;
}

#testimoni {
  color: $body-color;

  &.bg-img-head {
    padding-top: 1rem;
  }

  .card {
    background: #FFFFFFF2;
    box-shadow: 0px 0px 10px 0px #00000026;
  }
}

#service {
  .card {
    box-shadow: 0px 0px 10px 0px #00000026;

    .item {
      a {
        text-decoration: none;

        h5 {
          color: $black;
        }

        &:hover {
          h5 {
            color: $red;
          }
        }
      }
    }
  }
}

#contact {
  .form-control {
    // height: 60px;
    padding: 1.5rem;
    background: #F1F1F1;
    margin-bottom: 2rem;
  }

  textarea.form-control {
    // height: 154px;
    padding: 1.5rem;
  }

  // .wrap-maps {
  //   right: 0;
  //   top: 0;
  //   bottom: 0;
  //   width: 49.5vw;
  // }
}

#promo-produk {
  padding: 5rem 0;
  background-color: #F4F4F4;

  .slick-prev:before,
  .slick-next:before {
    color: $black;
    font-size: 40px;
  }

  .slick-dots {
    bottom: -25px;

    li {
      width: 44px;

      button:before {
        content: "\f068";
        font-size: 44px;
        color: #D9D9D9;
        opacity: 1;
      }

      &.slick-active button:before {
        color: #9C9C9C;
        opacity: 1;
      }
    }
  }
}

#promo-list {
  .slick-prev {
    left: -25px;
  }

  .slick-next {
    right: -25px;
  }
}

.product-thumbnail {
  &.card {
    background-color: transparent;
  }

  .card-body {
    font-size: 12.11px;

    a {
      text-decoration: none;

      p {
        color: $black;
      }

      .price {
        .normal {
          color: $black;
          font-size: 11.54px;
          margin-right: 4px;
          text-decoration: line-through;
        }

        .promo {
          color: $red;
          font-weight: 600;
        }
      }
    }
  }

  .img-wrap {
    position: relative;
    overflow: hidden;

    &:hover {
      .wrap-hover {
        visibility: visible;
        opacity: 1;
        background: rgba(40, 40, 54, 0.8);
      }
    }

    .wrap-hover {
      position: absolute;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      visibility: hidden;
      text-align: center;
      transition: opacity .3s ease, visibility .3s ease, transform .3s ease;
      backface-visibility: hidden;
      z-index: 3;
      opacity: 0;

      a {
        color: $white;

        i {
          font-size: 22px;
        }

        &:hover {
          color: $red;
        }

        &:nth-child(2) {
          margin: 0 20px;
        }
      }
    }
  }
}

.pagination {
  .page-item {
    &.disabled {
      &>.page-link {
        color: #cccccc;
        background-color: $white;
      }
    }

    &.active {
      &>.page-link {
        color: $white;
        background-color: #282836;
        border-color: #282836;
      }
    }

    .page-link {
      color: $black;

      &:hover {
        color: $white;
        background-color: #282836;
      }
    }

    // &.prev, &.next {
    //   &>.page-link {
    //     background-color: #f5f5f5;
    //   }
    // }
  }
}

.pagination {
  &.pagination-dark {

    .page-item {
      &.disabled {
        &>.page-link {
          color: #cccccc;
          border-color: $white;
          background-color: transparent;
        }
      }

      &.active {
        &>.page-link {
          color: #282836;
          border-color: $white;
          background-color: $white;
        }
      }

      .page-link {
        color: $white;
        background-color: transparent;

        &:hover {
          color: #282836;
          background-color: $white;
        }
      }

      // &.prev, &.next {
      //   &>.page-link {
      //     background-color: #f5f5f5;
      //   }
      // }
    }
  }
}

#product-detail {
  .btn-back {
    width: fit-content;
    color: $black;
    text-decoration: none;

    i {
      font-size: 22px;
      margin-right: 6px;
    }

    &:hover {
      color: $red;
    }
  }
}

#img-product {

  .slick-prev:before,
  .slick-next:before {
    color: $black;
  }

  .slick-dots {
    bottom: -25px;

    li {
      width: 44px;

      button:before {
        content: "\f068";
        font-size: 44px;
        color: #D9D9D9;
        opacity: 1;
      }

      &.slick-active button:before {
        color: #9C9C9C;
        opacity: 1;
      }
    }
  }
}

#product-tab {
  .nav-link {
    color: $black;
    padding: 10px 34px;
    border: 0;
    border-radius: 0;
    background-color: #F1F1F1;

    &.active {
      background-color: $white;
    }
  }
}

#footer {
  background-color: #282836;

  a {
    color: $white !important;
    text-decoration: none;

    &:hover {
      color: $red !important;
      text-decoration: underline;
    }
  }

  .copyright {
    border-top: 1px solid;
    border-color: #4E5683;
  }
}

#icon-bar-fixed {
  position: fixed;
  top: 35%;
  left: 0;
  transform: translateY(-50%);
  z-index: 999;

  a {
    text-decoration: none;
    border-radius: 0 6px 6px 0;
    margin-bottom: .5rem;
    width: 220px;
    margin-left: -165px;
    transition: all .5s ease;

    .icon-text {
      color: $black;
    }

    &:hover {
      margin-left: 0;

      &.icon-wa {
        background: #BADAC3;
      }

      &.icon-phone {
        background: #FDC8CD;
      }

      &.icon-mail {
        background: #FCE9B6;
      }

      &.icon-ig {
        background: #FFDCC2;
      }

      &.icon-fb {
        background: #CADCFF;
      }
    }

    .fa-stack {
      margin-right: -.8rem;
      margin-top: -3px;
      margin-bottom: -4px;
    }
  }
}

//---------------------------------------------
// 3.Components
//---------------------------------------------

// @import "components/slider";
@import "components/slick";

//---------------------------------------------
// 4.Responsive
//---------------------------------------------

@import "responsive";