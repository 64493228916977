@import "~select2/src/scss/core";

// @import "~bootstrap/scss/functions";
// @import "~bootstrap/scss/variables";
// @import "~bootstrap/scss/mixins";

// Or if you have already imported Bootstrap:
$s2bs5-border-color: $border-color;
$s2bs5-padding-y: .5rem;
$s2bs5-padding-x: .5rem;

@import "~select2-bootstrap-5-theme/src/include-all";


// .select2 {
//   width: 100% !important;
// }

/* bootstrap5 select2 custom */
.select2-container--bootstrap-5 {

  &.select2-container--focus,
  &.select2-container--open {
    .select2-selection {
      box-shadow: none;
    }
  }

  .select2-selection--single {
    height: auto !important;
    border-bottom: 1px solid rgba(59, 58, 58, 0.23);

    .select2-selection__rendered {
      color: rgb(59, 58, 58);
    }
  }

  .select2-dropdown {
    background-color: $white;

    .select2-search {
      .select2-search__field {
        color: rgb(59, 58, 58);
        border-bottom: 1px solid rgba(59, 58, 58, 0.23);

        &:focus {
          box-shadow: none;
          // border-color: rgba(59, 58, 58, 0.23);
        }
      }
    }

    .select2-results__options {
      .select2-results__option {
        color: rgb(59, 58, 58);

        &.select2-results__option--selected {
          color: $white;
        }

        &.select2-results__message {
          color: rgb(59, 58, 58);
        }
      }
    }
  }

}

// .select2-container .select2-selection--single
// .select2-container--bootstrap-5 .select2-selection--single .select2-selection__rendered

// .select2-container--bootstrap-5.select2-container--focus .select2-selection,
// .select2-container--bootstrap-5.select2-container--open .select2-selection

// .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__message
// .select2-container--bootstrap-5 .select2-dropdown .select2-search
// .select2-container--bootstrap-5 .select2-dropdown .select2-search .select2-search__field
// .select2-container--bootstrap-5 .select2-dropdown .select2-search .select2-search__field:focus

// .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option.select2-results__option--selected,
// .select2-container--bootstrap-5 .select2-dropdown .select2-results__options .select2-results__option[aria-selected=true]:not(.select2-results__option--highlighted)